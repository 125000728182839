<template>
	<!-- <div class="notice_popup human int">
		<div class="tit" style="margin-left: 9px;">이력서를 등록해 주세요!</div>
		<div class="member" style="line-height: 35px;">‘이력서’를 등록해 주세요!<br/>하이프로의 신뢰높은 프로젝트에 지원 하실 수 있습니다!</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', {viewNm:'MYP101M01'})">마이페이지로 가기</div>
			<div class="btn" @click="$emit('close', {viewNm:'PRF101M01'})">이력서 등록하기</div>
		</div>
	</div> -->
	<div class="popup-proj">
		<div class="close" @click="$emit('close', {viewNm:'MYP101M01'})">
			<img src="/images/close.png" alt="닫기">
		</div>
		<div class="tit-meg">
			프로젝트에 지원하기 위한 
			<span style="text-emphasis: filled black;">필수 등록항목</span> 입니다!
		</div>
		<div class="btn-box">
			<div class="box-item yellow" @click="$emit('close', {viewNm:'PRF101M01'})">
				<div class="tit">
					<img src="/images/icon_profile_01.png" alt="이력서등록icon">
					이력서 등록
				</div>
				<span>경력, 학력 등 이력정보를 등록해 주세요!</span>
				<img v-if="prfCnt == 0" src="/images/btn_profile_notregistered.png" alt="미등록">
				<img v-else src="/images/btn_profile_registration.png" alt="등록완료">
			</div>
			<div class="box-item" @click="$emit('close', {viewNm:'PRF104M01'})">
				<div class="tit">
					<img src="/images/icon_profile_02.png" alt="소속사등록icon">
					소속사 등록
				</div>
				<span>회원님의 소속사를 입력해 주세요!</span>
				<img v-if="mberAsgRegYn == 'N'" src="/images/btn_profile_notregistered.png" alt="미등록">
				<img v-else src="/images/btn_profile_registration.png" alt="등록완료">
			</div>
		</div>
		<div class="info-meg">
			※ ‘내 프로필 관리’ 메뉴에서 등록 및 수정이 가능하며, 프로젝트에 지원하기 위한 필수적인 등록항목 입니다!
		</div>
	</div>
</template>

<script>
export default {
	props: ['param'],

	data() {
		return {
			prfCnt : this.param.prfCnt,			
			mberAsgRegYn : this.param.mberAsgRegYn,
		};
	},

	methods : {
		
	}
}
</script>